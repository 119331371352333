@font-face {
  font-family: "Times New Roman", Times, serif;
  src: url("/fonts/times_new_roman/times_new_roman.ttf");
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: "Times New Roman";
}

.textBox {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* transform: translate(-50%, -50%); */
  z-index: 10;
}

.firefly {
  position: relative;
  top: 0;
  left: 0;
}

.notification-card {
  position: relative;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgb(153, 153, 153);
  animation-name: notification-card-motion;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  /* animation-iteration-count: infinite; */
  /* animation-direction: alternate; */
}

@keyframes notification-card-motion {
  0% {
    top: -50px;
    /* opacity: 0.6; */
  }

  25% {
    top: -10px;
    /* opacity: 0.8; */
  }

  50% {
    top: 10px;
    /* opacity: 0.9; */
  }

  25% {
    top: 2px;
    /* opacity: full; */
  }
}

@keyframes notification-card-scroll {
  0% {
    top: -10vh;
  }

  10% {
    top: 10vh;
    opacity: 0.9;
  }

  20% {
    top: 20vh;
    opacity: 0.8;
  }

  30% {
    top: 30vh;
    opacity: 0.7;
  }

  40% {
    top: 40vh;
    opacity: 0.6;
  }

  50% {
    top: 50vh;
    opacity: 0.5;
  }

  60% {
    top: 60vh;
    opacity: 0.4;
  }

  70% {
    top: 70vh;
    opacity: 0.3;
  }

  80% {
    top: 80vh;
    opacity: 0.2;
  }

  90% {
    top: 90vh;
    opacity: 0.1;
  }

  100% {
    top: 100vh;
    opacity: 0;
    /* height: 0px !important;
    width: 0px !important; */
  }
}

ul.fireflies {
  list-style: none;
}


ul.fireflies li {
  z-index: 100;
  /*   background-size: cover;
  background-image: url(http://i.imgur.com/aa7Rw.png); */
  box-shadow: 0 0 50px 15px var(--anim-color);
  width: 25px;
  top: -20px;
  height: 25px;
  border-radius: 50%;
  position: fixed;
  animation: leftright 24s infinite cubic-bezier(0.39, 0, 0.63, 1),
    updown 8s infinite 1.25s cubic-bezier(0.39, 0, 0.63, 1),
    appear .1s 1;
  animation-delay: var(--anim-positioning), var(--anim-positioning), var(--anim-delay);
  animation-duration: var(--lr-duration), var(--ud-duration);
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes leftright {

  0%,
  100% {
    left: 20%;
  }

  16.666% {
    left: calc(100% - 32px);
  }

  33.333% {
    left: 1%;
  }

  50% {
    left: 50%;
  }

  66.666% {
    left: 70%;
  }

  83.333% {
    left: 40%;
  }
}

@keyframes updown {

  0%,
  100% {
    top: 0px;
    width: 20px;
    height: 20px;
  }

  25% {
    top: 70%;
  }

  50% {
    top: 50%;
    width: 30px;
    height: 30px;
  }

  75% {
    top: calc(100% - 20px);
    width: 10px;
    height: 10px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: antiquewhite;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #81ddf6;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Social Sharing Links */
.social-share a:hover {
  opacity: 100%;
}

.social-share a {
  transition: all 0.8s ease;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Override Tailwind background opacity */
.btn-disabled,
.btn-disabled:hover,
.btn[disabled],
.btn[disabled]:hover {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 0.8;
  --tw-text-opacity: 0.8;
  background-color: hsl(219 88.085% 88.843%/var(--tw-bg-opacity));
  color: hsl(215 88.907% 88.863%/var(--tw-text-opacity));
}

.pinned_wish {
  box-shadow: 0 0 30px 9px rgb(233, 187, 233, 0.7), inset 0 0 10px 5px rgb(233, 220, 233, 0.3);
}
